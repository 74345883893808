import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8045dfe4&scoped=true"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8045dfe4",
  null
  
)

export default component.exports